<template>
  <div>
    <v-snackbar class="snakbar-sty rounded-pill" transition="slide-x-reverse-transition" v-model="snackbar" :timeout="3000" :value="true" color="#2A2A2A" absolute outlined>
      <v-icon class="mr-2" color="#2A2A2A">mdi-alert-outline</v-icon>
      {{ toasttext }}
      <v-icon @click="snackbar = false" class="float-right" color="#2A2A2A">mdi-close-circle</v-icon>
    </v-snackbar>
    <div class="body-row pb-3 px-4 px-md-6 px-lg-8 pt-lg-6">
      <img src="@/assets/zebu.svg" class="ml-n1" style="max-width: 90px" alt="zebulogo" />
      <v-card class="elevation-0">
        <p class="subtitle-2 font-weight-bold mb-5 txt-666">Welcome to Zebu</p>
        <p class="headline font-weight-black mb-8">Login to Desk</p>
        <div v-if="!conformdialog">
          <p class="font-weight-medium fs-17 mb-0">
            Enter your Client ID/Mobile to begin
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
              <path
                d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                fill="#EF5350"
              />
            </svg>
          </p>

          <v-form ref="form" v-model="valid" @submit.prevent="Otp_screen ? setOtpVerify() : setOtpSend()">
            <v-text-field class="mb-1" placeholder="Client ID/Mobile" :readonly="Otp_screen" required :rules="clientRules" oninput="this.value = this.value.toUpperCase()" v-model="client_id" @keypress="phonevalidation($event)">
              <template #prepend-inner>
                <div class="mr-2">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.1333 12.6438C14.1335 12.4937 14.0975 12.3457 14.0282 12.2125C13.959 12.0792 13.8587 11.9647 13.7357 11.8785C12.052 10.7881 10.0709 10.248 8.06667 10.3329C6.06245 10.248 4.08138 10.7881 2.3976 11.8785C2.27466 11.9647 2.17432 12.0792 2.1051 12.2125C2.03588 12.3457 1.99983 12.4937 2 12.6438V14.9996H14.1333V12.6438Z"
                      stroke="#212121"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M8.06647 7.53333C9.8706 7.53333 11.3331 6.0708 11.3331 4.26667C11.3331 2.46254 9.8706 1 8.06647 1C6.26234 1 4.7998 2.46254 4.7998 4.26667C4.7998 6.0708 6.26234 7.53333 8.06647 7.53333Z" stroke="#212121" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </template>

              <template #append>
                <a v-if="Otp_screen" style="text-decoration: none; color: #0037b7; font-size: 12px" href=""><b>Change</b></a>
              </template>
            </v-text-field>

            <div v-if="Otp_screen" class="mt-2">
              <p class="font-weight-medium fs-17 mb-0">
                Enter 4 digit OTP sent to mobile/email<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                  <path
                    d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                    fill="#EF5350"
                  />
                </svg>
              </p>

              <v-text-field class="mb-1" placeholder="0000" required type="number" hide-spin-buttons v-model="validotp" ref="mobileotp" :rules="otpRules" :readonly="cardloader" @input="autoVerify()" @keypress="OtpValidation($event)" counter="4">
                <template #prepend-inner>
                  <div class="mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g clip-path="url(#clip0_419_1733)">
                        <path
                          d="M15 0H7.5C7.23478 0 6.98043 0.105357 6.79289 0.292893C6.60536 0.48043 6.5 0.734784 6.5 1V8.5C6.5 8.59286 6.52586 8.68388 6.57467 8.76287C6.62349 8.84185 6.69334 8.90569 6.77639 8.94721C6.85945 8.98874 6.95242 9.00632 7.0449 8.99798C7.13738 8.98964 7.22572 8.95571 7.3 8.9L9.1665 7.5H15C15.2652 7.5 15.5196 7.39464 15.7071 7.20711C15.8946 7.01957 16 6.76522 16 6.5V1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0ZM11 5.5H8.5V4.5H11V5.5ZM14 3H8.5V2H14V3Z"
                          fill="#666666"
                        />
                        <path
                          d="M10.5 8.5V13.5C10.5 13.6326 10.4473 13.7598 10.3536 13.8536C10.2598 13.9473 10.1326 14 10 14H2.5C2.36739 14 2.24021 13.9473 2.14645 13.8536C2.05268 13.7598 2 13.6326 2 13.5V3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H5.5V1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V14.5C1 14.8978 1.15804 15.2794 1.43934 15.5607C1.72064 15.842 2.10218 16 2.5 16H10C10.3978 16 10.7794 15.842 11.0607 15.5607C11.342 15.2794 11.5 14.8978 11.5 14.5V8.5H10.5Z"
                          fill="#666666"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_419_1733">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </template>

                <template #append>
                  <div>
                    <span v-if="timerEnabled" class="font-weight-medium txt-666 fs-12">Resend in {{ timerCount }}</span>
                    <v-btn v-if="resendText" :disabled="cardloader" @click="setOtpSend('rotp')" color="#0037B7" text plain x-small>
                      <span class="font-weight-medium fs-12 text-none">Resend OTP</span>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
            </div>
            <div class="mt-4" :style="`cursor: ${valid != true ? 'not-allowed' : cardloader == true ? 'not-allowed' : validotp.length != 4 ? 'not-allowed' : 'auto'};`">
              <v-btn height="48px" :loading="cardloader" :disabled="!valid || cardloader || conformdialog" @click="Otp_screen ? setOtpVerify() : setOtpSend()" type="submit" color="#000" class="text-none rounded-pill elevation-0" block
                ><span class="white--text subtitle-1 font-weight-medium">Continue</span></v-btn
              >
            </div>
            <p class="subtitle-2 font-weight-medium txt-666 mb-4 my-4 text-center">By clicking continue, you agree to our <a href="https://zebuetrade.com/termsandconditions" style="text-decoration: none; color: #0037b7">Terms & Conditions</a>.</p>
          </v-form>
          <p class="subtitle-2 font-weight-medium black--text mb-0 text-center mt-2">Don't have an account yet? <a style="text-decoration: none; color: #0037b7" href="https://oa.mynt.in?ref=desk">Sign Up</a></p>
        </div>
      </v-card>
      <v-dialog v-model="conformdialog" persistent max-width="380">
        <v-card class="rounded-xl elevation-0 pb-3" width="380px">
          <v-card-title class="text-h5 font-weight-bold"> Confirmation </v-card-title>
          <v-card-text class="subtitle-1 font-weight-medium black--text">{{ text }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="rounded-xl elevation-0 font-weight-medium text-none" color="black" outlined @click="(conformdialog = false), (cardloader = false)"> Cancel </v-btn>
            <v-btn class="rounded-xl elevation-0 font-weight-medium text-none" color="primary" @click="setRedirects"> Ok </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-footer padless absolute color="#fff">
        <v-col class="text-center font-weight-medium fs-10 px-lg-8 px-md-6 px-lg-8 pb-2 txt-666" cols="12"
          ><span class="font-weight-bold fs-12 black--text"> Zebu Share and Wealth Managements Pvt. Ltd. </span><br />
          SEBI Registration No: INZ000174634 | NSE : 13179 | BSE : 6550 | MCX : 55730 | CDSL : 12080400 | AMFI ARN : 113118 | Research Analyst : INH200006044
        </v-col>
      </v-footer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      mobile_num: "",
      client_id: "",
      validotp: "",

      valid: false,

      errorOTPDisp: false,
      Otp_screen: false,
      cardloader: false,
      resendloader: false,
      toasttext: null,
      resendText: false,
      snackbar: false,
      timerCount: 120,
      timerEnabled: false,
      otpbutton: true,
      wayis: true,

      // mobileRules: [
      //   (v) => !!v || "Your Mobile number is required",
      //   (v) =>
      //     /^[6-9][0-9]{9}$/.test(v) || "Mobile number must be in 10 digit.",
      //   (v) => (v || '').indexOf(' ') < 0 ||
      //     'No spaces are allowed'
      // ],
      clientRules: [(v) => !!v || "Your Client ID/Mobile is required", (v) => (v || "").indexOf(" ") < 0 || "No spaces are allowed"],
      otpRules: [(v) => !!v || "OTP is required", (v) => (v || "").length == 4 || "Enter 4 digit OTP.", (v) => (v || "").indexOf(" ") < 0 || "No spaces are allowed"],
      progress: 0,

      ipaddress: "null",
      navi: {},
      conformdialog: false,
      text: null,
      texturl: null,
    };
  },

  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
        if (value == 0) {
          this.timerEnabled = false;
          this.resendText = true;
          this.resendloader = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  created() {
    this.getPublicIP();
    this.navi = {
      appCodeName: navigator.appCodeName,
      appVersion: navigator.appVersion,
      userAgentData: navigator.userAgentData,
      userActivation: navigator.userActivation,
      connection: navigator.connection,
      cookieEnabled: navigator.cookieEnabled,
      geolocation: navigator.geolocation,
    };
  },
  mounted() {
    var href = new URL(window.location.href).searchParams;
    var url = href.get("url");
    if (href && href.get("cli")) {
      this.client_id = href.get("cli");
      this.setOtpSend("", true);
    }

    var actid = localStorage.getItem("userid");
    var token = localStorage.getItem("usession");

    let data = JSON.stringify({
      clientid: actid,
      token: token,
      log: {
        datetime: new Date().toLocaleString(),
        login_type: "validate",
        src: url ? url.split("https://")[1] : "profile.mynt.in D",
        IP_address: this.ipaddress,
        app: navigator.userAgent,
        sso: navigator.vendor,
        platform: navigator.userAgentData ? navigator.userAgentData.platform : "null",
        device: JSON.stringify(this.navi),
      },
    });

    let config = {
      method: "post",
      url: "https://rekycbe.mynt.in/autho/validate_session",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        axiosThis.client_id = actid;
        if (response.data.msg == "valid token") {
          axiosThis.text = `Continue to login with this Client ID: ${actid}`;
          axiosThis.conformdialog = true;
          axiosThis.texturl = `${url ? url : "https://profile.mynt.in/"}?uid=${actid}&token=${token}`;
        } else {
          // localStorage.removeItem("userid");
          localStorage.removeItem("usession");
        }

        // if (response.data.msg == "valid token") {
        //   confirm(`Continue to login with this client ID: ${actid}`, function (result) {
        //     if (result) {
        //       window.location.reload();
        //       window.location.assign(`${url ? url : "https://profile.mynt.in/"}?uid=${actid}&token=${token}`);
        //     } else {
        //       //
        //     }
        //   });
        // } else if (actid) {
        //   axiosThis.client_id = actid;
        //   // localStorage.removeItem("userid");
        //   // localStorage.removeItem("usession");
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    setRedirects() {
      this.cardloader = true;
      window.location.reload();
      window.location.assign(this.texturl);
      this.conformdialog = false;
    },
    autoVerify() {
      if (this.validotp.length == 4) {
        this.setOtpVerify();
      }
    },
    setOtpVerify() {
      if (this.$refs.form.validate() == true && this.progress == 0) {
        var url = new URL(window.location.href).searchParams.get("url");
        var _navigator = {};
        for (var i in window.navigator) _navigator[i] = navigator[i];
        this.progress = 1;
        this.cardloader = true;
        var json_data;
        var input = Number(this.client_id);
        if (isNaN(input)) {
          json_data = {
            otp: this.validotp,
            clientid: this.client_id.toUpperCase(),
          };
          this.wayis = true;
        } else {
          json_data = {
            otp: this.validotp,
            mobile: this.client_id,
          };
          this.wayis = false;
        }
        json_data["log"] = {
          datetime: new Date().toLocaleString(),
          login_type: this.wayis ? "mobile Otp" : "client Otp",
          src: url ? url.split("https://")[1] : "profile.mynt.in D",
          IP_address: this.ipaddress,
          app: navigator.userAgent,
          sso: navigator.vendor,
          platform: navigator.userAgentData ? navigator.userAgentData.platform : "null",
          device: JSON.stringify(this.navi),
        };
        var config = {
          method: "post",
          url: "https://rekycbe.mynt.in//autho/otp_verify",
          headers: {"Content-Type": "application/json"},
          data: JSON.stringify(json_data),
        };
        var axiosThis = this;

        axios(config)
          .then(function (response) {
            if (response.data && response.data.clientid && response.data.token) {
              localStorage.setItem("userid", response.data.clientid);
              localStorage.setItem("usession", response.data.token);
              window.location.reload();
              window.location.assign(`${url ? url : "https://profile.mynt.in/"}?uid=${response.data.clientid}&token=${response.data.token}`);
              axiosThis.errorOTPDisp = false;
            } else {
              axiosThis.snackbar = true;
              axiosThis.toasttext = response.data.emsg ? response.data.emsg : "OTP is Invalid!";
              axiosThis.cardloader = false;
              axiosThis.validotp = "";
              axiosThis.errorOTPDisp = true;
            }
            setTimeout(() => {
              axiosThis.progress = 0;
            }, 3000);
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.snackbar = true;
            axiosThis.toasttext = error;
            axiosThis.cardloader = false;
            axiosThis.validotp = "";
            axiosThis.errorOTPDisp = true;
          });
      }
    },
    setOtpSend(val, startis) {
      let start = false;
      if (val == "rotp") {
        this.resendloader = true;
        start = true;
      } else if (startis) {
        this.progress = 1;
        start = true;
      } else {
        if (this.$refs.form.validate() == true && this.progress == 0) {
          this.progress = 1;
          start = true;
        }
      }
      if (start) {
        this.cardloader = true;
        var json_data;
        var input = Number(this.client_id);
        if (isNaN(input)) {
          json_data = {
            field: "clientid",
            value: this.client_id.toUpperCase(),
          };
          this.wayis = true;
        } else {
          json_data = {
            field: "mobile",
            value: this.client_id,
          };
          this.wayis = false;
        }
        var config = {
          method: "post",
          url: "https://rekycbe.mynt.in/autho/otp_send",
          headers: {"Content-Type": "application/json"},
          data: JSON.stringify(json_data),
        };

        var axiosThis = this;
        axios(config)
          .then(function (resp) {
            if (resp.data.msg == "otp sended") {
              axiosThis.timerCount = 120;
              if (val == "rotp") {
                axiosThis.resendText = false;
                axiosThis.resendloader = false;
              } else {
                if (axiosThis.resendloader == true) {
                  axiosThis.resendText = false;
                  axiosThis.resendloader = false;
                } else {
                  axiosThis.Otp_screen = true;
                  setTimeout(() => axiosThis.$refs.mobileotp.$refs.input.focus(), 100);
                }
              }
              axiosThis.timerEnabled = true;
            } else {
              axiosThis.client_id = "";
            }
            axiosThis.toasttext = resp.data.msg == "otp sended" ? "OTP Sent successfully." : resp.data.msg;
            axiosThis.snackbar = true;
            if (val != "rotp") {
              setTimeout(() => {
                axiosThis.progress = 0;
              }, 3000);
            }
            axiosThis.cardloader = false;
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.snackbar = true;
            axiosThis.toasttext = error;
            axiosThis.cardloader = false;
          });
      }
    },

    phonevalidation($event) {
      // var keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      // if ((keyCode > 31 && (keyCode < 48 || keyCode > 57))) { // && keyCode !== 46  this if for dot key
      //   $event.preventDefault();
      // }
      if (this.mobile_num.length >= 10) {
        $event.preventDefault();
      }
    },

    OtpValidation($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
        // && keyCode !== 46  this if for dot key
        $event.preventDefault();
      }
      if (this.validotp) {
        this.errorOTPDisp = false;
        if (this.validotp.length >= 4) {
          $event.preventDefault();
        }
      }
    },

    async getPublicIP() {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        this.ipaddress = data.ip;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
